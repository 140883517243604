/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { Account } from './../types';
import { DEFAULT_ACCOUNT_TYPE, ListAccountsAction, ListAccountsActionTypes, ListAccountsFilter } from './actions';
import { SelectOption } from '../../../../components/ui/redesigned-select';

export interface AccountsState {
  currentPage: number;
  error?: Error;
  loading?: boolean;
  accounts: Account[];
  filter?: ListAccountsFilter;
  searchString: string;
  selectedAccounts: any[];
  size: number;
  total: number;
  totalBalance: number;
  filterValues: Record<string, SelectOption<string>>;
}

const size: number = 20;

export const initialAccountsState: AccountsState = {
  accounts: [],
  currentPage: 1,
  filter: {},
  loading: false,
  searchString: '',
  selectedAccounts: [],
  size,
  total: 0,
  totalBalance: 0,
  filterValues: {},
};

export default function accountsReducer(state = initialAccountsState, action: ListAccountsAction) {
  switch (action.type) {
    case ListAccountsActionTypes.LIST_ACCOUNTS_REQUEST:
      const { currentPage, searchString, filter, size } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        currentPage: { $set: currentPage },
        filter: { $set: filter },
        searchString: { $set: searchString },
        size: { $set: size },
      });

    case ListAccountsActionTypes.LIST_ACCOUNTS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        accounts: { $set: action.accounts },
        total: { $set: action.total },
        totalBalance: { $set: action.totalAmount || 0 },
      });

    case ListAccountsActionTypes.LIST_ACCOUNTS_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case ListAccountsActionTypes.SELECT_ACCOUNT:
      const isSelected = state.selectedAccounts.some((acc) => acc.id === action.account.id);

      if (action.single) {
        return update(state, {
          selectedAccounts: {
            $set: [action.account],
          },
        });
      }

      return update(state, {
        selectedAccounts: {
          $set: isSelected
            ? state.selectedAccounts.filter((acc) => acc.id !== action.account.id)
            : [...state.selectedAccounts, action.account],
        },
      });

    case ListAccountsActionTypes.RESET_SELECTED_ACCOUNT:
      return update(state, {
        selectedAccounts: { $set: initialAccountsState.selectedAccounts },
      });
    case ListAccountsActionTypes.RESET_ERROR:
      return update(state, { $unset: ['error'] });

    case ListAccountsActionTypes.SET_FILTER_VALUE:
      return update(state, {
        filterValues: {
          $set: {
            ...state.filterValues,
            [action.key]: action.value
          }
        }
      });

    case ListAccountsActionTypes.CLEAR_FILTER_VALUE:
      const newFilterValues = { ...state.filterValues };
      delete newFilterValues[action.key];
      
      return update(state, {
        filterValues: { $set: newFilterValues }
      });

    case ListAccountsActionTypes.CLEAR_ALL_FILTER_VALUES:
      return update(state, {
        filterValues: { $set: {} }
      });

    case ListAccountsActionTypes.RESET_STATE:
      return update(state, {
        currentPage: { $set: initialAccountsState.currentPage },
        accounts: { $set: initialAccountsState.accounts },
        error: { $set: initialAccountsState.error },
        loading: { $set: initialAccountsState.loading },
        filter: { $set: initialAccountsState.filter },
        searchString: { $set: initialAccountsState.searchString },
        selectedAccounts: { $set: initialAccountsState.selectedAccounts },
        size: { $set: initialAccountsState.size },
        total: { $set: initialAccountsState.total },
        totalBalance: { $set: initialAccountsState.totalBalance },
        filterValues: { $set: initialAccountsState.filterValues },
      });

    default:
      return state;
  }
}
