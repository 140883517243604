/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { Account } from '../../../../components/accounts/listAccounts/types';
import { NameRequiredException, StatusRequiredException } from '../../../services/createService/redux/exceptions';
import { UpdateAccountAction, UpdateAccountActionTypes, UpdateAccountInput, UpdateForm } from './types';
import { validateAccountFields } from './validate';

export interface UpdateAccountState {
  error?: Error;
  loading?: boolean;
  account?: Account;
  isConfirmationPopupAccount?: boolean;
  success?: boolean;
  accountFields: UpdateAccountInput;
  nameError?: { code: NameRequiredException };
  statusError?: { code: StatusRequiredException };
}

export const initialUpdateAccountState: UpdateAccountState = {
  isConfirmationPopupAccount: false,
  loading: false,
  account: null,
  success: false,
  accountFields: {
    id: null,
    name: '',
    status: {
      statusType: null,
      statusReason: '',
    },
  },
};

export default function updateAccountReducer(state = initialUpdateAccountState, action: UpdateAccountAction) {
  switch (action.type) {
    case UpdateAccountActionTypes.UPDATE_ACCOUNT_REQUEST:
      return update(state, {
        loading: { $set: true },
        success: { $set: false },
        isConfirmationPopupAccount: { $set: false },
      });

    case UpdateAccountActionTypes.UPDATE_ACCOUNT_SUCCESS:
      return update(state, {
        loading: { $set: false },
        account: { $set: action.account },
        isConfirmationPopupAccount: { $set: false },
        success: { $set: true },
      });

    case UpdateAccountActionTypes.UPDATE_ACCOUNT_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
        isConfirmationPopupAccount: { $set: false },
        success: { $set: false },
      });

    case UpdateAccountActionTypes.RESET_UPDATE_ACCOUNT:
      return update(state, {
        account: { $set: null },
        loading: { $set: false },
        success: { $set: false },
        isConfirmationPopupAccount: { $set: false },
        accountFields: { $set: initialUpdateAccountState.accountFields },
      });

    case UpdateAccountActionTypes.RESET_UPDATE_ACCOUNT_ERROR:
      return update(state, {
        error: { $set: null },
        loading: { $set: false },
        isConfirmationPopupAccount: { $set: false },
      });

    case UpdateAccountActionTypes.SHOW_CONFIRMATION_ACCOUNT_POPUP:
      return update(state, {
        isConfirmationPopupAccount: { $set: true },
      });

    case UpdateAccountActionTypes.HIDE_CONFIRMATION_ACCOUNT_POPUP:
      return update(state, {
        isConfirmationPopupAccount: { $set: false },
      });

    case UpdateAccountActionTypes.CLOSE_SUCCESS_POPUP:
      return update(state, {
        success: { $set: false },
      });

    case UpdateAccountActionTypes.UPDATE_ACCOUNT_FIELDS:
      return validateAccountFields(state, action.accountFields);

    default:
      return state;
  }
}
