/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { GetItemAction, GetItemActionTypes, ItemQrCode } from './types';

export interface GetItemState {
  item: ItemQrCode | null;
  error: Error | null;
  loading: boolean;
}

export const initialGetItemState: GetItemState = {
  item: null,
  error: null,
  loading: false,
};

export default function getItemReducer(state = initialGetItemState, action: GetItemAction): GetItemState {
  switch (action.type) {
    case GetItemActionTypes.GET_ITEM_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case GetItemActionTypes.GET_ITEM_SUCCESS:
      return update(state, {
        item: { $set: action.item },
        loading: { $set: false },
      });

    case GetItemActionTypes.GET_ITEM_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case GetItemActionTypes.RESET_ITEM:
      return initialGetItemState;

    case GetItemActionTypes.RESET_ITEM_ERROR:
      return update(state, {
        error: { $set: null },
      });

    default:
      return state;
  }
}
