import { gql } from '@apollo/client';

export const getItemQuery = gql`
  query getItem($input: ServiceInput!) {
    getItem(input: $input) {
      id
      name_en
      name_ru
      name_ky
      status
      qrCode {
        image
        url
      }
      account {
        name
      }
      callbackUrl
      endDate
      fixedAmount
      maxAmount
      maxAvailableQuantity
      merchantCategoryCode
      minAmount
      popularityScore
      startDate
    }
  }
`;
