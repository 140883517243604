/* External dependencies */
import gql from 'graphql-tag';

export const listUsersQuery = gql`
  query listUsers($input: ListUsersInput!) {
    listUsers(input: $input) {
      total
      users {
        id
        lastName
        firstName
        identificationStatus
        identificationDocument {
          id
          documentIdentificationStatus
          faceIdentificationStatus
        }
        joinDate
        phone
        groupName
        status
      }
    }
  }
`;

export const listUsersForManagerQuery = gql`
  query listDealers($input: ListUsersInput!) {
    listDealers(input: $input) {
      total
      users {
        id
        lastName
        firstName
        accounts {
          id
        }
        identificationStatus
        joinDate
        phone
        groupName
        status
      }
    }
  }
`;

export const listPermissionsQuery = gql`
  query listPermissions($input: ListPermissionsInput!) {
    listPermissions(input: $input) {
      total
      permissionDetails {
        id
        name
      }
    }
  }
`;
