/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../../clients/averspay';
import { ClientServiceLimits } from '../types';
import {
  GetClientServiceLimitsAction,
  GetClientServiceLimitsActionTypes,
  getClientServiceLimitsError,
  GetClientServiceLimitsRequest,
  getClientServiceLimitsSuccess,
} from './actions';
import { getClientServiceLimitsQuery } from './query';

export default function getClientServiceLimitsEpic(action$) {
  return action$.pipe(
    filter(
      (action: GetClientServiceLimitsAction) =>
        action.type === GetClientServiceLimitsActionTypes.GET_CLIENT_SERVICE_LIMITS_REQUEST,
    ),
    switchMap((action: GetClientServiceLimitsRequest) =>
      getClientServiceLimits(action).then(getClientServiceLimitsSuccess).catch(getClientServiceLimitsError),
    ),
  );
}

export async function getClientServiceLimits(action: GetClientServiceLimitsRequest): Promise<ClientServiceLimits> {
  const graphQLClient = await getClient();
  const { serviceId } = action;

  const {
    data: { getClientServiceLimits },
  } = await graphQLClient.query({
    query: getClientServiceLimitsQuery,
    variables: {
      input: {
        serviceId,
      },
    },
  });

  return getClientServiceLimits;
}
