/* External dependencies */
import { debounceTime, filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/averspay';
import { UserRole } from '../../../components/common/roles';
import { SessionUser } from '../../../components/login/redux/actions';
import { hasAccess } from '../../../permissions/page-permissions';
import { getUserRole } from '../../common/helpers';
import {
  ListServices,
  ListServicesAction,
  ListServicesActionTypes,
  listServicesFailed,
  listServicesSucceeded,
  ListServicesSuccess,
  ServiceType,
} from './actions';
import { listServicesQuery } from './queries';

export default function listServicesEpic(action$, state$) {
  return action$.pipe(
    filter((action: ListServicesAction) => action.type === ListServicesActionTypes.LIST_SERVICES_REQUEST),
    debounceTime(300),
    switchMap((action: ListServices) =>
      listServices(action, state$?.value?.login?.currentUser).catch((error) => listServicesFailed(error)),
    ),
  );
}

export async function listServices(
  { filter = {}, currentPage, searchString, startDate, endDate, size }: ListServices,
  currentUser: SessionUser,
): Promise<ListServicesSuccess> {
  const graphQLClient = await getClient();
  const userRole = getUserRole(currentUser);

  if (filter.typename === ServiceType.ITEM) {
    delete filter.parentId;

    if (!hasAccess([UserRole.ADMIN, UserRole.MANAGER], userRole) && !filter.userId) {
      filter.userId = currentUser.sub;
    }
  }

  const {
    data: {
      listServices: { services, total },
    },
  } = await graphQLClient.query({
    query: listServicesQuery,
    variables: {
      input: {
        filter,
        from: (currentPage - 1) * size,
        query: searchString,
        size,
        startDate,
        endDate,
      },
    },
  });

  const nonEmptyServices = services?.filter((service) => {
    return service && (filter.typename !== ServiceType.ITEM ? service.typename !== ServiceType.ITEM : true);
  });

  return listServicesSucceeded(total, nonEmptyServices);
}
